.upload-button{
    height: 32px;
    width: 75px;
    background-color: #ffffffcc;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    transition: 0.3s ease-in-out;
    >.upload-button-input{
        cursor: pointer;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 1;
        opacity: 0;
    }
    >div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        width: 100%;
        height: 100%;
        background-color: transparent;
        >p{
            color: #3D3D3D;
            font-size: 12px;
            letter-spacing: 0.2px;
            font-weight: 400;
        }
        >span{
            width: 16px;
            height: 16px;
            min-width: 16px;
        }
    }
    &:hover{
        background-color: #ffffff;
    }
}