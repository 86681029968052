.login-page{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.login-page>.logo{
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 80px;
    gap: 80px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #003399 0%, #001133 100%);
}
.login-page>.logo>img{
    width: 300px;
    height: 300px;
}
.login-page>.logo>p{
    font-size: 24px;
    font-weight: 400;
    font-style: italic;
    color: var(--white);
    letter-spacing: 1.5px;
    width: fit-content;
}
.login-page>.logo>p>span{
    color: #33BBFF;
}
.login-page>.login{
    width: 50%;
}
.login-page>.login>.login-form{
    gap: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 80PX 275px;
}
.login-page>.login>.login-form>div{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    align-self: flex-start;
}
.login-page>.login>.login-form>div>h1{
    font-size: 40px;
    color: #33BBFF;
    font-weight: 400;
}
.login-page>.login>.login-form>div>p{
    font-size: 20px;
    color: #003399;
    font-weight: 300;
    font-style: italic;
}
.login-page>.login>.login-form>form{
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: fit-content;
}
.login-page>.login>.login-form>form>.inputs{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.login-page>.login>.login-form>form>.inputs>div{
    width: 400px;
    height: 67px;
    display: flex;
    gap: 20px;
    padding: 20px;
    align-items: center;
    background-color: var(--white);
    border: 1px solid #003399;
    transition: 0.3s ease-in-out;
}
.login-page>.login>.login-form>form>.inputs>div>input{
    width: 94%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: #003399;
}
.login-page>.login>.login-form>form>.inputs>div>input::placeholder{
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    color: #003399;
}
.login-page>.login>.login-form>form>.inputs>div>input:focus{
    border: none;
    outline: none;
}
.login-page>.login>.login-form>form>.inputs>div:focus-within{
    box-shadow: 0px 0px 8px 1px #00339980;
}
.login-page>.login>.login-form>form>.inputs>div>svg{
    width: 22px;
    min-width: 22px;
    height: 22px;
}
.login-page>.login>.login-form>form>.inputs>div>span{
    width: 22px;
    position: relative;
    min-width: 22px;
    height: 22px;
    cursor: pointer;
}
.login-page>.login>.login-form>form>.inputs>div>span>.icon1{
    opacity: 1;
    transition: 0.3s ease-in-out;
}
.login-page>.login>.login-form>form>.inputs>div>span>.icon1.hidden{
    opacity: 0;
}
.login-page>.login>.login-form>form>.inputs>div>span>.icon2{
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
}
.login-page>.login>.login-form>form>.inputs>div>span>.icon2.active{
    opacity: 1;
}
.login-page>.login>.login-form>form>button{
    width: 100%;
    height: 67px;
    background-color: #FFDD33;
    border: 1px solid #003399;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 15px;
    color: #003399;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}
.login-page>.login>.login-form>form>button>svg{
    width: 22px;
    height: 22px;
}
.login-page>.login>.login-form>form>button:hover{
    box-shadow: 0px 0px 4px 1px #00339980;
}
.login-page>.login>.login-form>p{
    color: #33BBFF;
    font-size: 20px;
    align-self: flex-start;
    border-bottom: 1px solid #33BBFF;
    cursor: pointer;
    width: fit-content;
}