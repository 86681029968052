.products {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    height: 100vh;

    >.sub-nav-bar {
        display: flex;
        width: fit-content;
        gap: 8px;
        height: 56px;
        display: flex;
        padding: 8px;
        border-radius: 8px;
        border: 1px solid var(--grey);

        >div {
            display: flex;

            &.new-prod {
                height: 40px;
                align-items: center;
                padding: 0px 8px;
                gap: 8px;
                width: fit-content;
                border-right: 1px solid var(--light-grey);

                >.add-new-prod {
                    display: flex;
                    padding: 2px 16px;
                    gap: 4px;
                    height: 32px;
                    background-color: transparent;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--blue);
                    border-radius: 8px;
                    color: var(--blue);
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: capitalize;
                    transition: 0.3s ease-in-out;

                    >span {
                        width: 16px;
                        height: 16px;
                        min-width: 12px;
                    }

                    &:hover {
                        background-color: var(--light-blue);
                    }
                }

                >.confirm {
                    display: flex;
                    gap: 4px;
                    padding: 2px 12px;
                    height: 32px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    background-color: var(--light-blue);
                    color: var(--blue);
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: capitalize;
                    transition: 0.3s ease-in-out;

                    >span {
                        width: 16px;
                        height: 16px;
                        min-width: 12px;
                    }

                    &:hover {
                        border: 1px solid var(--blue);
                    }
                }

                >.cancel {
                    display: flex;
                    gap: 4px;
                    padding: 2px 12px;
                    height: 32px;
                    align-items: center;
                    justify-content: center;
                    border: 1px solid transparent;
                    border-radius: 8px;
                    background-color: var(--light-grey);
                    color: var(--dark-grey);
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: capitalize;
                    transition: 0.3s ease-in-out;

                    >span {
                        width: 16px;
                        height: 16px;
                        min-width: 12px;
                    }

                    &:hover {
                        border: 1px solid var(--dark-grey);
                    }
                }
            }

            &.filter {
                height: 40px;
                display: flex;
                align-items: center;
                gap: 8px;
                border-right: 1px solid var(--light-grey);
                padding: 2px 8px 2px 0px;

                >div {
                    padding: 0px 16px;
                    border: 1px solid var(--light-grey);
                    border-radius: 16px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-size: 14px;
                    font-weight: 400;
                    color: var(--dark-grey);
                    letter-spacing: 0.5px;
                    cursor: pointer;
                    transition: 0.3s ease-in-out;

                    >span {
                        font-size: 12px;
                        font-weight: 400;
                        color: #33333380;
                        letter-spacing: 0.5px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        transition: 0.3s ease-in-out;
                    }

                    &:hover {
                        background-color: var(--light-grey);
                    }

                    &.active {
                        background-color: var(--light-blue);
                        border: 1px solid transparent;
                        color: var(--blue);

                        // >p{
                        // }
                        >span {
                            color: #0088CC80;
                        }
                    }
                }
            }

            &.pagination {
                display: flex;
                align-items: center;
                // padding: 2px 8px 2px 16px;
                gap: 16px;

                >p {
                    font-size: 14px;
                    color: var(--dark-grey);
                    font-weight: 400;
                    letter-spacing: 0.2px;
                }

                >div {
                    display: flex;

                    >span {
                        align-items: center;
                        justify-content: center;
                        width: 32px;
                        height: 32px;
                        border-radius: 8px;
                        display: flex;
                        cursor: pointer;

                        &:hover {
                            background-color: var(--light-grey);
                        }

                        &:active {
                            background-color: var(--light-blue);
                        }

                        &.left {
                            &.disabled {
                                pointer-events: none;
                                cursor: default;
                            }
                        }

                        &.right {
                            >svg {
                                transform: rotate(180deg);
                            }

                            &.disabled {
                                pointer-events: none;
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

    }

    >.table {
        >.thead {
            >div {
                display: grid;
                align-items: center;

                &.first {
                    height: 56px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid var(--grey);
                    grid-column: 1/ span 3;
                    grid-template-columns: subgrid;

                    >.check,
                    >.list,
                    >.number {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-right: 1px solid var(--light-grey);

                        >span {
                            height: 24px;
                            width: 24px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;

                        }
                    }

                    >.list {
                        position: relative;

                        >.items {
                            position: absolute;
                            width: 154px;
                            padding: 4px;
                            background-color: var(--white);
                            z-index: 1;
                            box-shadow: 0px 0px 8px 0px #0000001a;
                            border-radius: 8px;
                            top: 100%;
                            left: 20%;

                            >.item {
                                display: flex;
                                height: 32px;
                                padding: 2px 8px;
                                gap: 4px;
                                align-items: center;
                                justify-content: flex-start;
                                border-radius: 4px;
                                transition: 0.3s ease-in-out;
                                cursor: pointer;

                                >span {
                                    width: 16px;
                                    height: 16px;
                                }

                                >p {
                                    font-size: 12px;
                                    letter-spacing: 0.2px;
                                    color: #3D3D3D;
                                    font-weight: 400;
                                }

                                &:hover {
                                    background-color: var(--light-grey);
                                }
                            }
                        }
                    }

                    >.check {
                        span {
                            background-color: var(--light-grey);
                            border-radius: 6px;

                            &.all {
                                background-color: var(--light-blue);
                            }

                            &.almost {
                                background-color: var(--light-blue);
                            }
                        }
                    }

                    >.number {
                        border-right: 0px;
                    }
                }

                &.separator {
                    height: 100%;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >div {
                        height: 100%;
                        width: 1px;
                        background-color: var(--grey);
                    }
                }

                &.second {
                    height: 48px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid var(--grey);
                    grid-column: 5/ span 4;
                    grid-template-rows: subgrid;
                    grid-template-columns: subgrid;

                    >.info {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        &:first-child,
                        &:nth-child(2) {
                            >p {
                                >span {
                                    display: none;
                                }
                            }
                        }

                        >p {
                            height: 100%;
                            width: 100%;
                            padding: 0px 16px;
                            border-right: var(--light-grey) 1px solid;
                            font-size: 14px;
                            font-weight: 400;
                            text-align: left;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            color: var(--dark-grey);
                            letter-spacing: 0.5px;
                            position: relative;

                            >span {
                                width: 32px;
                                min-width: 32px;
                                height: 32px;
                                border-radius: 8px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                opacity: 0;
                                transition: 0.3s ease-in-out;
                                background-color: transparent;
                                cursor: pointer;

                                &.active {
                                    opacity: 1;
                                    background-color: var(--light-blue);

                                }
                            }

                            &:hover {
                                >span {
                                    opacity: 1;
                                    background-color: var(--light-grey);
                                }

                                >span.active {
                                    background-color: var(--light-blue);
                                }
                            }
                        }

                        &:last-child {
                            >p {
                                border-right: none;
                            }
                        }
                    }
                }

                &.last {
                    height: 48px;
                    padding-bottom: 8px;
                    border-bottom: 1px solid var(--grey);
                    grid-column: 10/ span 2;
                    grid-template-rows: subgrid;
                    grid-template-columns: subgrid;

                    >.status,
                    >.created-at {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        >p {
                            width: 100%;
                            height: 100%;
                            padding-left: 16px;
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            color: var(--dark-grey);
                        }
                    }

                    >.status {
                        >p {
                            border-right: 1px solid var(--light-grey);
                        }
                    }
                }

            }
        }

        .tbody {
            >.product-line {
                display: grid;
                grid-template-rows: subgrid;
                grid-column: 1/ span 11;
                grid-template-columns: subgrid;
                cursor: pointer;

                >div {
                    display: grid;
                    align-items: center;

                    &.first {
                        height: 48px;
                        padding: 8px 0;
                        grid-column: 1/ span 3;
                        grid-template-columns: subgrid;
                        grid-template-rows: subgrid;
                        border-bottom: 1px solid var(--grey);

                        >.check,
                        >.list,
                        >.number {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: 0.3s ease-in-out;
                            border-right: 1px solid var(--light-grey);
                            cursor: pointer;

                            >span {
                                height: 32px;
                                width: 32px;
                                min-width: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: 0.3s ease-in-out;
                                border-radius: 8px;

                                &.active {
                                    background-color: var(--light-blue);
                                }
                            }
                        }

                        >.check {
                            >span {
                                >.checkbox {
                                    width: 24px;
                                    min-width: 24px;
                                    height: 24px;
                                    cursor: pointer;
                                    border-radius: 6px;
                                    background-color: var(--light-grey);
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    transition: 0.3s ease-in-out;

                                    >svg {
                                        >g {
                                            >path {
                                                stroke: var(--grey);
                                                transition: 0.3s ease-in-out;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        >.number {
                            border-right: none;
                        }

                        >.list {
                            position: relative;

                            >.list-items {
                                position: absolute;
                                width: 154px;
                                padding: 4px;
                                background-color: var(--white);
                                z-index: 1;
                                box-shadow: 0px 0px 8px 0px #0000001a;
                                border-radius: 8px;
                                top: 100%;
                                left: 20%;

                                >.item {
                                    display: flex;
                                    height: 32px;
                                    padding: 2px 8px;
                                    gap: 4px;
                                    align-items: center;
                                    justify-content: flex-start;
                                    border-radius: 4px;
                                    transition: 0.3s ease-in-out;
                                    cursor: pointer;

                                    >span {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    >p {
                                        font-size: 12px;
                                        letter-spacing: 0.2px;
                                        color: #3D3D3D;
                                        font-weight: 400;
                                    }

                                    &:hover {
                                        background-color: var(--light-grey);
                                    }

                                    &.delete {
                                        >p {
                                            color: #FF0000;
                                        }

                                        &:hover {
                                            background-color: #fee5e9;
                                        }
                                    }
                                }
                            }
                        }

                    }

                    &.separator {
                        height: 100%;
                        width: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        >div {
                            height: 100%;
                            width: 1px;
                            background-color: var(--grey);
                        }
                    }

                    &.second {
                        height: 48px;
                        padding: 8px 0;
                        border-bottom: 1px solid var(--grey);
                        grid-column: 5/ span 4;
                        grid-template-columns: subgrid;
                        grid-template-rows: subgrid;

                        >.info {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            overflow: hidden;

                            >.input {
                                display: flex;
                                align-items: center;
                                gap: 6px;
                                justify-content: flex-start;

                                >input {
                                    width: 100%;
                                    border: none;
                                    outline: none;
                                    font-size: 14px;
                                    color: var(--dark-grey);
                                    letter-spacing: 0.2px;
                                    font-weight: 400;
                                    background-color: transparent;

                                    &::placeholder {
                                        color: var(--dark-grey);
                                        font-size: 14px;
                                        letter-spacing: 0.2px;
                                        font-weight: 400;
                                    }
                                }

                                >span {
                                    width: 32px;
                                    height: 32px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    min-width: 32px;
                                    border-radius: 8px;
                                    background-color: var(--light-grey);
                                }
                            }

                            >div {
                                height: 48px;
                                width: 100%;
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 0px 16px;
                                border-right: var(--light-grey) 1px solid;
                                transition: 0.3s ease-in-out;

                                >p {
                                    font-size: 14px;
                                    font-weight: 400;
                                    text-align: left;
                                    letter-spacing: 0.5px;
                                    color: var(--dark-grey);
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;

                                    &.categ {
                                        height: 32px;
                                        border-radius: 16px;
                                        background-color: var(--light-grey);
                                        padding: 0px 16px;
                                        width: fit-content;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        transition: 0.3s ease-in-out;
                                    }

                                    >img {
                                        width: 32px;
                                        height: 32px;
                                        cursor: pointer;
                                    }
                                }


                                >.icons {
                                    display: flex;
                                    width: fit-content;
                                    align-items: center;
                                    gap: 4px;
                                    display: none;

                                    >span {
                                        width: 32px;
                                        min-width: 32px;
                                        height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        cursor: pointer;
                                        border-radius: 8px;
                                        transition: 0.3s ease-in-out;

                                        &:hover {
                                            background-color: var(--light-grey);
                                        }

                                        &.active {
                                            background-color: var(--light-blue);
                                        }
                                    }
                                }

                                &:hover {
                                    >.icons {
                                        display: flex;
                                    }
                                }

                            }

                            &.img {
                                position: relative;
                                overflow: visible;

                                >.images {
                                    position: absolute;
                                    width: 168px;
                                    height: fit-content;
                                    z-index: 1;
                                    top: 90%;
                                    left: 40%;
                                    padding: 4px;
                                    display: flex;
                                    flex-direction: column;
                                    gap: 4px;
                                    box-shadow: 0px 0px 8px 0px #0000001a;
                                    background-color: var(--white);
                                    border-radius: 8px;
                                    cursor: default;

                                    >.current-img {
                                        width: 160px;
                                        height: 164px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        position: relative;
                                        border-bottom: 1px solid var(--light-grey);

                                        >img {
                                            width: 100%;
                                            height: 160px;
                                            position: absolute;
                                            top: 0;

                                        }
                                    }

                                    >.other-imgs {
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 3px;
                                        height: 160px;
                                        overflow: scroll;

                                        >.image {
                                            display: flex;
                                            width: 78px;
                                            height: 78px;
                                            min-width: 78px;
                                            background-color: var(--white);
                                            padding: 4px;
                                            border-radius: 8px;
                                            border: 1px solid var(--light-grey);
                                            cursor: pointer;
                                            transition: 0.3s ease-in-out;

                                            >img {
                                                width: 100%;
                                                height: 100%;
                                                object-fit: contain;
                                            }

                                            &:hover {
                                                border: 1px solid var(--grey);
                                            }

                                            &.selected {
                                                border: 1px solid var(--blue);
                                            }
                                        }
                                    }
                                }
                            }

                            &:last-child {
                                >div {
                                    border-right: none;
                                }
                            }
                        }

                    }

                    &.last {
                        height: 48px;
                        padding: 8px 0;
                        border-bottom: 1px solid var(--grey);
                        grid-column: 10/ span 2;
                        grid-template-columns: subgrid;
                        grid-template-rows: subgrid;
                        border-bottom: 1px solid var(--grey);
                        position: relative;

                        >.status,
                        >.created-at {
                            height: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            gap: 4px;

                            >div {
                                width: 100%;
                                height: 48px;
                                display: flex;
                                padding: 0px 16px;
                                align-items: center;
                                transition: 0.3s ease-in-out;
                                padding-left: 8px;
                                font-size: 14px;
                                color: var(--dark-grey);
                            }
                        }

                        >.status {
                            >div {
                                border-right: 1px solid var(--light-grey);
                                justify-content: flex-start;
                                text-transform: capitalize;
                                gap: 6px;

                                >.point {
                                    width: 8px;
                                    min-width: 8px;
                                    height: 8px;
                                    border-radius: 50%;
                                }

                                >span {
                                    width: 32px;
                                    min-width: 32px;
                                    height: 32px;
                                    border-radius: 8px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    opacity: 0;
                                    transition: 0.3s ease-in-out;
                                    cursor: pointer;
                                    margin-left: auto;

                                    &.active {
                                        opacity: 1;
                                        background-color: var(--light-blue);

                                        &:hover {
                                            background-color: var(--light-blue);
                                        }
                                    }
                                }
                            }

                            &:hover {
                                >div {
                                    >span {
                                        opacity: 1;

                                        &:hover {
                                            background-color: var(--white);
                                        }
                                    }
                                }

                                >span.active {
                                    background-color: var(--light-blue);

                                    &:hover {
                                        background-color: var(--light-blue);
                                    }
                                }
                            }
                        }

                        >.list-status {
                            position: absolute;
                            display: none;
                            width: 151px;
                            background-color: var(--white);
                            z-index: 1;
                            border-radius: 8px;
                            box-shadow: 0px 0px 8px 1px #0000001a;
                            padding: 4px;
                            // left: 35%;
                            top: 86%;

                            >ul {
                                border-radius: 4px;
                                width: 100%;

                                >li {
                                    display: flex;
                                    height: 32px;
                                    align-items: center;
                                    gap: 8px;
                                    font-size: 12px;
                                    border-radius: 4px;
                                    letter-spacing: 0.2px;
                                    transition: 0.3s ease-in-out;
                                    cursor: pointer;

                                    >span {
                                        width: 32px;
                                        min-width: 32px;
                                        height: 32px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;

                                        >.radio {
                                            width: 16px;
                                            height: 16px;
                                            border-radius: 50%;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            border: 1px solid var(--grey);
                                            background-color: transparent;

                                            >div {
                                                border-radius: 50%;
                                                width: 8px;
                                                height: 8px;
                                                background-color: var(--light-grey);
                                                transition: 0.3s ease-in-out;
                                            }

                                            &.active {
                                                >div {
                                                    background-color: var(--blue);
                                                }
                                            }
                                        }
                                    }

                                    &:hover {
                                        background-color: var(--light-grey);

                                        >span {
                                            >.radio {
                                                >div {
                                                    background-color: var(--white);
                                                }

                                                &.active {
                                                    >div {
                                                        background-color: var(--blue);
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &.active {
                                display: flex;
                            }
                        }

                    }
                }


                &:nth-last-of-type(2) {
                    >.first {
                        >.list {
                            position: relative;

                            >.list-items {
                                position: absolute;
                                width: 154px;
                                padding: 4px;
                                background-color: var(--white);
                                z-index: 1;
                                box-shadow: 0px 0px 8px 0px #0000001a;
                                border-radius: 8px;
                                bottom: 100%;
                                left: 20%;
                                top: auto;

                                >.item {
                                    display: flex;
                                    height: 32px;
                                    padding: 2px 8px;
                                    gap: 4px;
                                    align-items: center;
                                    justify-content: flex-start;
                                    border-radius: 4px;
                                    transition: 0.3s ease-in-out;
                                    cursor: pointer;

                                    >span {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    >p {
                                        font-size: 12px;
                                        letter-spacing: 0.2px;
                                        color: #3D3D3D;
                                        font-weight: 400;
                                    }

                                    &:hover {
                                        background-color: var(--light-grey);
                                    }
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    >.first {
                        >.list {
                            position: relative;

                            >.list-items {
                                position: absolute;
                                width: 154px;
                                padding: 4px;
                                background-color: var(--white);
                                z-index: 1;
                                box-shadow: 0px 0px 8px 0px #0000001a;
                                border-radius: 8px;
                                bottom: 100%;
                                left: 20%;
                                top: auto;

                                >.item {
                                    display: flex;
                                    height: 32px;
                                    padding: 2px 8px;
                                    gap: 4px;
                                    align-items: center;
                                    justify-content: flex-start;
                                    border-radius: 4px;
                                    transition: 0.3s ease-in-out;
                                    cursor: pointer;

                                    >span {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    >p {
                                        font-size: 12px;
                                        letter-spacing: 0.2px;
                                        color: #3D3D3D;
                                        font-weight: 400;
                                    }

                                    &:hover {
                                        background-color: var(--light-grey);
                                    }
                                }
                            }
                        }
                    }

                    >.first,
                    >.second,
                    >.last {
                        padding-bottom: 0;
                        border-bottom: none;
                    }

                    >.second {
                        >.info {
                            border-bottom: none;
                        }
                    }
                }

                &:first-child {
                    >.first {
                        >.list {
                            position: relative;

                            >.list-items {
                                position: absolute;
                                width: 154px;
                                padding: 4px;
                                background-color: var(--white);
                                z-index: 1;
                                box-shadow: 0px 0px 8px 0px #0000001a;
                                border-radius: 8px;
                                bottom: auto;
                                left: 20%;
                                top: 100%;

                                >.item {
                                    display: flex;
                                    height: 32px;
                                    padding: 2px 8px;
                                    gap: 4px;
                                    align-items: center;
                                    justify-content: flex-start;
                                    border-radius: 4px;
                                    transition: 0.3s ease-in-out;
                                    cursor: pointer;

                                    >span {
                                        width: 16px;
                                        height: 16px;
                                    }

                                    >p {
                                        font-size: 12px;
                                        letter-spacing: 0.2px;
                                        color: #3D3D3D;
                                        font-weight: 400;
                                    }

                                    &:hover {
                                        background-color: var(--light-grey);
                                    }
                                }
                            }
                        }
                    }
                }

                &.add-new {
                    >.first {
                        background-color: #00000007;

                        >div {
                            background-color: white;
                        }
                    }

                    >.second {
                        background-color: #00000007;

                        >.info {
                            background-color: white;

                            >div {
                                >.icons {
                                    display: flex;

                                    >span {
                                        background-color: var(--light-grey);
                                    }
                                }
                            }
                        }
                    }

                    >.last {
                        background-color: #00000007;

                        div {
                            background-color: white;
                        }
                    }

                    >.first,
                    >.second,
                    >.last {
                        padding-bottom: 8px;
                        border-bottom: 1px solid var(--grey);
                    }
                }

                &:hover {

                    >.first {
                        background-color: transparent;

                        >.check,
                        >.list,
                        >.number {
                            border-right: 1px solid var(--white);
                            background-color: var(--light-grey);
                        }

                        >.number {
                            border-right: none;
                        }

                        >.check {
                            >span {
                                >.checkbox {
                                    background-color: var(--white);
                                }
                            }
                        }
                    }

                    >.second {
                        background-color: transparent;

                        >.info {
                            >div {
                                border-right: 1px solid var(--white);
                                background-color: var(--light-grey);
                                background-color: var(--light-grey);

                                >p {
                                    &.categ {
                                        background-color: var(--white);
                                    }
                                }

                                >.icons {
                                    >span {
                                        &:hover {
                                            background-color: var(--white);
                                        }

                                        &.active {
                                            background-color: var(--light-blue);
                                        }
                                    }
                                }
                            }

                            >.input-container {
                                background-color: var(--white);
                            }
                        }
                    }

                    >.last {
                        background-color: transparent;

                        >.status,
                        >.created-at {
                            >div {
                                background-color: var(--light-grey);
                            }
                        }

                        >.status {
                            >div {
                                border-right: 1px solid var(--white);
                            }
                        }
                    }

                }

                &.active {
                    >.first {

                        >.check,
                        >.list,
                        >.number {
                            border-right: 1px solid var(--white);
                            background-color: var(--light-blue);
                        }

                        >.number {

                            border-right: none;

                        }

                        >.check {
                            >span {
                                >.checkbox {
                                    background-color: var(--blue);

                                    >svg {
                                        >g {
                                            >path {
                                                stroke: var(--white);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    >.second {
                        background-color: transparent;

                        >.info {
                            >div {

                                border-right: 1px solid var(--white);
                                background-color: var(--light-blue);
                                background-color: var(--light-blue);

                                >p.categ {
                                    background-color: var(--white);
                                }
                            }

                        }
                    }

                    >.last {

                        >.status>div,
                        >.created-at>div {
                            background-color: var(--light-blue);
                        }

                        >.status {
                            >div {
                                border-right: 1px solid var(--white);
                            }
                        }

                    }
                }
            }
        }
    }

    >.message {
        width: 100%;
        background-color: var(--light-blue);
        color: var(--blue);
        padding: 8px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-end;
        
        >p {
            color: var(--blue);
            text-transform: uppercase;
            font-size: 18px;
            letter-spacing: 0.2px;
        }

        &.error {
            background-color: #FFC7CE;

            >p {
                color: #FF0000;
            }
        }

    }
}