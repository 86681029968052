.dropdown {
    position: relative;
    display: inline-block;

    >.dropdown-toggle {
        display: flex;
        height: 40px;
        min-width: 144px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 8px 0px 16px;
        // gap: 8px;
        border-right: 1px solid var(--light-grey);
        position: relative;

        >p {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.5px;
            text-align: left;
            text-transform: capitalize;
        }

        >span {
            max-width: 32px;
            height: 32px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            background-color: var(--light-grey);
            transition: 0.3s ease-in-out;
            cursor: pointer;

            >svg {
                transform: rotate(180deg);
                transition: 0.3s ease-in-out;

                >g {
                    >path {
                        stroke: black;
                    }
                }
            }

            &.active {
                background-color: var(--light-blue);

                >svg {
                    transform: rotate(0deg);

                    >g {
                        >path {
                            stroke: var(--blue);
                        }
                    }
                }
            }
        }
    }

    >.dropdown-menu {
        position: absolute;
        min-width: 154px;
        display: flex;
        flex-direction: column;
        padding: 4px;
        gap: 4px;
        background-color: var(--white);
        z-index: 1;
        top: 40px;
        box-shadow: 0px 0px 8px 1px #0000001a;
        border-radius: 8px;
        left: 120px;

        >li {
            display: flex;
            font-size: 12px;
            height: 32px;
            gap: 8px;
            align-items: center;
            justify-content: flex-start;
            padding: 8px;
            color: #3D3D3D;
            transition: 0.3s ease-in-out;
            background-color: transparent;
            border-radius: 4px;
            text-transform: capitalize;
            cursor: pointer;

            >.check {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid var(--grey);
                display: flex;
                align-items: center;
                justify-content: center;
                >div{
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: var(--light-grey);
                    transition: 0.3s ease-in-out;
                }
                &.active{
                    >div{
                        background-color: var(--blue);
                    }
                }
            }

            &:hover {
                background-color: var(--light-grey);
                >.check{
                    >div{
                        background-color: var(--white);
                    }
                    &.active{
                        >div{
                            background-color: var(--blue);
                        }
                    }
                }
            }
        }
    }
}