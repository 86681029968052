* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  width: 100vw;
  overflow: hidden;
}

#root {
    width: 100%;
}

body,
body * {
  font-family: 'Inter';
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

form {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}