$color_1: var(--dark-grey);
$color_2: var(--blue);
$background-color_1: var(--white);
$background-color_2: var(--light-blue);

.tabs {
	display: flex;
	height: 100vh;
	width: fit-content;

	>.tabs-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		border-right: 1px solid var(--grey);
		padding: 16px;
		min-width: 200px;
		width: 200px;

		>.top {
			display: flex;
			flex-direction: column;
			gap: 8px;
			height: 100%;
			width: 100%;

			>.tab {
				height: 48px;
				width: 100%;
				border-radius: 8px;

				background-color: $background-color_1;
				border: 1px solid var(--light-grey);
				display: flex;
				justify-content: flex-start;
				align-items: center;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				>a {
					width: 100%;
					height: 100%;
					display: flex;
					gap: 8px;
					padding: 0px 16px;
					justify-content: flex-start;
					align-items: center;

					>span {
						height: 24px;
						max-width: 24px;
						display: flex;
						align-items: center;
						justify-content: center;

						>svg {
							>g {
								>path {
									stroke: var(--dark-grey);
								}
							}
						}
					}

					>p {
						color: $color_1;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 1px;
						width: fit-content;
					}
				}

				&:hover {
					border: 1px solid var(--grey);
					background-color: var(--light-grey);
				}

				&.active {
					background-color: $background-color_2;
					border: 1px solid transparent;

					>a {
						>span {
							>svg {
								>g {
									>path {
										stroke: var(--blue);
									}
								}
							}
						}

						>p {
							color: $color_2;
						}
					}
				}
			}
		}

		>.bottom {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 100%;
			// height: 100%;
			margin-top: auto;
			position: relative;

			>.tab {
				height: 48px;
				width: 100%;
				border-radius: 8px;
				padding: 0px 16px;
				background-color: $background-color_1;
				border: 1px solid var(--light-grey);
				display: flex;
				gap: 8px;
				justify-content: flex-start;
				align-items: center;
				transition: 0.3s ease-in-out;
				cursor: pointer;

				>a {
					display: flex;
					justify-content: flex-start;
					align-items: center;
					gap: 8px;
					width: 100%;
					height: 100%;
					>span {
						width: 24px;
						min-width: 24px;
						height: 24px;
					}

					>p {
						color: $color_1;
						font-size: 16px;
						font-weight: 400;
						letter-spacing: 1px;
						width: fit-content;
					}
				}

				>span {
					width: 24px;
					min-width: 24px;
					height: 24px;
				}

				>p {
					color: $color_1;
					font-size: 16px;
					font-weight: 400;
					letter-spacing: 1px;
					width: fit-content;
				}

				&:hover {
					border: 1px solid var(--grey);
					background-color: var(--light-grey);
				}

				&.active {
					background-color: var(--light-blue);

					>a {
						>p {
							color: $color_2;
						}
					}

					>p {
						color: $color_2;
					}
				}
			}

			>.settings {
				width: 232px;
				height: fit-content;
				border-radius: 8px;
				box-shadow: 0px 0px 8px 0px #0000001a;
				background-color: var(--white);
				z-index: 1;
				position: absolute;
				bottom: 70%;
				left: calc(100% + 8px);
				transition: 0.3s ease-in-out;

				>.header {
					width: 100%;
					height: 32px;
					display: flex;
					justify-content: space-between;
					padding: 0px 4px 0px 8px;
					align-items: center;
					border-bottom: 1px solid var(--light-grey);

					>div {
						display: flex;
						align-items: center;
						gap: 4px;

						&:first-child {
							>span {
								width: 16px;
								min-width: 16px;
								height: 16px;
							}

							>p {
								font-size: 12px;
								font-weight: 400;
								letter-spacing: 0.2px;
								color: var(--dark-grey);
							}
						}

						&:last-child {
							>span {
								width: 24px;
								height: 24px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: var(--light-grey);
								border-radius: 4px;
								cursor: pointer;
							}
						}
					}
				}

				>.body {
					display: flex;
					flex-direction: column;
					gap: 16px;
					padding: 16px;

					>div {
						display: flex;
						flex-direction: column;
						gap: 4px;
						align-items: flex-start;

						>p {
							font-size: 12px;
							color: #999999;
							font-weight: 400;
							letter-spacing: 0.2px;
						}
					}
				}
			}

			>.profile {
				width: 232px;
				height: fit-content;
				border-radius: 8px;
				box-shadow: 0px 0px 8px 0px #0000001a;
				background-color: var(--white);
				z-index: 1;
				position: absolute;
				bottom: 35%;
				left: calc(100% + 8px);

				>.header {
					width: 100%;
					height: 32px;
					display: flex;
					justify-content: space-between;
					padding: 0px 4px 0px 8px;
					align-items: center;
					border-bottom: 1px solid var(--light-grey);

					>div {
						display: flex;
						align-items: center;
						gap: 4px;

						&:first-child {
							>span {
								width: 16px;
								min-width: 16px;
								height: 16px;
							}

							>p {
								font-size: 12px;
								font-weight: 400;
								letter-spacing: 0.2px;
								color: var(--dark-grey);
							}
						}

						&:last-child {
							>span {
								width: 24px;
								height: 24px;
								display: flex;
								align-items: center;
								justify-content: center;
								background-color: var(--light-grey);
								border-radius: 4px;
								cursor: pointer;
							}
						}
					}
				}

				>.body {
					display: flex;
					flex-direction: column;
					gap: 16px;
					padding: 16px;

					>div {
						display: flex;
						flex-direction: column;
						gap: 4px;
						align-items: flex-start;

						&.message {
							width: 100%;
							height: 32px;
							display: flex;
							justify-content: center;
							align-items: center;
							background-color: var(--light-blue);

							>p {
								color: var(--blue);
								text-transform: lowercase;
								font-size: 12px;
							}

							&.error {
								background-color: #FFC7CE;

								>p {
									color: #FF0000;
								}
							}
						}

						>p {
							font-size: 12px;
							color: #999999;
							font-weight: 400;
							letter-spacing: 0.2px;

						}

						>div {
							display: flex;
							justify-content: space-between;
							width: 100%;
							align-items: center;

							&.inputs-container {
								display: flex;
								flex-direction: column;
								gap: 8px;

								>button {
									width: 100%;
									background-color: var(--light-blue);
									color: var(--blue);
									padding: 8px;
									border: none;
									border-radius: 4px;
									transition: 0.3s ease-in-out;

									&:hover {
										background-color: #C5E1F5;
									}
								}
							}

							>p {
								width: 100%;
								height: fit-content;
								text-align: left;
								color: var(--dark-grey);
								font-size: 14px;
								letter-spacing: 0.2px;
								overflow: hidden;
								text-overflow: ellipsis;
							}

							>span {
								width: 24px;
								height: 24px;
								display: flex;
								align-items: center;
								justify-content: center;
								min-width: 24px;
								border-radius: 4px;
								cursor: pointer;
								transition: 0.3s ease-in-out;

								&:hover {
									background-color: var(--light-grey);
								}
							}
						}
					}

					>button {
						width: 100%;
						height: 32px;
						border-radius: 4px;
						background-color: #FFE5E5;
						color: #CC0000;
						font-size: 14px;
						font-weight: 400;
						letter-spacing: 0.2px;
						display: flex;
						align-items: center;
						justify-content: center;
						gap: 4px;
						border: none;
						transition: 0.3s ease-in-out;

						&:hover {
							background-color: #FFC0C0;
						}
					}
				}
			}
		}
	}

	>.children {
		display: none;
		width: 100%;
		height: 100vh;
	}

	>.children.active {
		display: flex;
	}
}