.table {
    width: 100%;
    height: fit-content;
    padding: 8px;
    display: grid;
    border-radius: 8px;
    border: 1px solid var(--grey);
    grid-template-columns: repeat(3, 48px) 17px repeat(4, 1fr) 17px repeat(2, 160px);

    >.thead {
        display: grid;
        grid-column: 1/ span 11;
        grid-template-columns: subgrid;
        align-items: center;

        >div {
            display: grid;
            align-items: center;
        }
    }

    >.tbody {
        display: grid;
        grid-template-columns: subgrid;
        grid-column: 1/ span 11;
        grid-template-columns: subgrid;
        // overflow: scroll;
        height: fit-content;
        // max-height: 680px;
    }
}