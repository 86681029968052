.phone-filter {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 4px;
    width: 220px;
    background-color: var(--white);
    box-shadow: 0px 0px 8px 0px #0000001a;
    border-radius: 8px;
    z-index: 1;
    top: 100%;
    right: 15px;

    >.search-input {
        width: 100%;
        height: 32px;
        display: flex;
        justify-content: flex-start;
        gap: 4px;
        border-radius: 4px;
        border: 1px solid var(--grey);
        align-items: center;
        padding: 0px 8px;
        transition: 0.3s ease-in-out;

        >input {
            width: 100%;
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 12px;
            font-weight: 400px;
            color: var(--dark-grey);
            height: 16px;

            &::placeholder {
                font-size: 12px;
                font-weight: 400px;
                color: var(--grey);
            }
        }

        &:focus-within {
            border: 1px solid var(--blue);
        }
    }

    >.container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        width: 100%;

        >.header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            height: 36px;
            padding: 0px 4px 0px 8px;
            align-items: center;
            border-bottom: 1px solid var(--light-grey);

            >.check {
                display: flex;
                align-items: center;
                gap: 8px;
                height: 100%;

                >div {
                    width: 20px;
                    height: 20px;
                    background-color: var(--light-grey);
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &.all {
                        background-color: var(--light-blue);
                    }

                    &.almost {
                        background-color: var(--light-blue);
                    }
                }

                >p {
                    font-size: 14px;
                    letter-spacing: 0.2px;
                    color: var(--dark-grey);
                    font-weight: 400;
                }
            }
        }

        >.options {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 120px;
            overflow: scroll;
            scrollbar-width: thin;
            scrollbar-color: #CCC var(--white);

            >.option-container {
                display: flex;
                padding: 0px 8px;
                align-items: center;
                justify-content: flex-start;
                gap: 8px;
                height: 36px;
                min-height: 36px;
                transition: 0.3s ease-in-out;
                cursor: pointer;

                >.option-check {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    background-color: var(--light-grey);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: 0.3s ease-in-out;
                }

                >.option {
                    display: flex;
                    gap: 6px;
                    align-items: center;
                    justify-content: flex-start;

                    >.option-flag {
                        height: 17px;
                        width: 17px;

                        // border-radius: 50%;
                        >img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    >.option-name {
                        font-size: 14px;
                        color: var(--dark-grey);
                        letter-spacing: 0.2px;
                        font-weight: 400;
                    }
                }

                &:hover {
                    background-color: var(--light-grey);

                    >.option-check {
                        background-color: var(--white);
                    }
                }

                &.active {
                    >.option-check {
                        background-color: var(--blue);
                    }
                }
            }
        }
    }
}